var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-icon',{staticStyle:{"border":"1px solid #5E6D7E","border-radius":"4px"}},[_vm._v(" handshake ")])],1),_c('v-col',{staticClass:"card-title-font"},[_vm._v("Comercial")])],1)],1),_c('CustomTabsComercialComponent',{attrs:{"selectedTab":_vm.selectedTab}}),_c('v-card',{staticClass:"pa-4 card-digital",class:_vm.isMobile ? 'mt-4' : 'ma-4'},[_c('v-card-title',{staticClass:"fira-semi-bold title-parcerias"},[_vm._v(" Parcerias ")]),_c('v-card-text',[_c('v-row',{staticClass:"mb-0",attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{attrs:{"rounded":"","dense":"","hide-details":"","outlined":"","label":"Pesquise uma parceria","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Período (Data)","outlined":"","rounded":"","dense":"","hide-details":"","value":_vm.formattedDate}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.statusOptions,"item-text":"text","item-value":"value","append-icon":"keyboard_arrow_down","label":"Status","outlined":"","rounded":"","dense":"","hide-details":"","menu-props":{ offsetY: true }},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ButtonComponent',{attrs:{"propDisabled":_vm.isClearFiltersDisabled,"propTitle":"Limpar todos os filtros","propClick":_vm.clearFilters}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"dense":"","items":_vm.headers,"item-text":"text","item-value":"value","multiple":"","label":"Editar colunas","hide-details":"","outlined":"","rounded":"","loading":_vm.loadingSave,"menu-props":{ offsetY: true }},on:{"input":_vm.onChangeSelectAllCheckbox},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.selectCheckbox.length === 1)?_c('span',{staticClass:"purplemain--text"},[_vm._v(" "+_vm._s(item.text)+" ")]):(index === 0)?_c('span',{staticClass:"purplemain--text"},[_vm._v(" "+_vm._s(_vm.selectCheckbox.length)+" filtros selec. ")]):_vm._e()]}}]),model:{value:(_vm.selectCheckbox),callback:function ($$v) {_vm.selectCheckbox=$$v},expression:"selectCheckbox"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ButtonComponent',{attrs:{"propTitle":"Criar Parceria","propClick":_vm.navigateToCreateParceria}})],1)],1)],1),_c('v-card-text',{class:_vm.isMobile ? 'pa-2' : ''},[_c('v-data-table',{ref:"tabela",staticClass:"table-ticket pa-4 articulat-medium",attrs:{"headers":_vm.headersFiltered,"items":_vm.filteredParcerias,"dense":"","fixed-header":"","height":"75vh","loading-text":"Carregando...","footer-props":{
          itemsPerPageOptions: [-1],
        },"multi-sort":"","hide-default-footer":"","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('imprimir-tabela-component',{staticStyle:{"flex-grow":"inherit"},attrs:{"propRef":[_vm.tabelaRef]}})]},proxy:true},{key:"item.nome",fn:function(ref){
        var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.nome)),"propObjectId":item.id,"propToolName":"Parcerias"}})]}},{key:"item.data_inicio",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_inicio))+" ")]}},{key:"item.nicho_id",fn:function(ref){
        var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaNichoOptions(), function (i) { return i.id == value; }).name)+" ")]):_vm._e()]}},{key:"item.descricao",fn:function(ref){
        var value = ref.value;
return [_c('text-expandable-component',[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.status_id",fn:function(ref){
        var value = ref.value;
return [(value)?_c('v-chip',{class:_vm.statusClass(value),attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaStatusOptions(), function (i) { return i.id == value; }).name)+" ")]):_vm._e()]}},{key:"item.data_termino",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_termino))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }