import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, parceriaStore, filtroPorTabelaStore } from '@/store';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import _ from 'lodash';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Nome', value: 'nome', show: true },
            { text: 'Status', value: 'status_id', show: true },
            { text: 'Nicho', value: 'nicho_id', show: true },
            { text: 'Descrição', value: 'descricao', show: true },
            { text: 'Data de início', value: 'data_inicio', show: true },
            { text: 'Data de término', value: 'data_termino', show: true },
            { text: 'Entrada de cupons', value: 'total_entrada', show: true },
            { text: 'Saída de cupons', value: 'total_saida', show: true },
            { text: 'Saldo de cupons', value: 'total_saldo', show: true },
            { text: 'CEP', value: 'cep' },
            { text: 'Endereço', value: 'rua' },
            { text: 'Número de endereço', value: 'numero' },
            { text: 'Complemento', value: 'complemento' },
            { text: 'Bairro', value: 'bairro' }
            // { text: 'Ações', value: 'actions', show: true },
            // { text: 'Ultimo contato', value: 'ultimo_contato', show: true },
        ];
        this.statusOptions = [
            { text: 'Em prospecção', value: 1 },
            { text: 'Em negociação', value: 2 },
            { text: 'Parceria não consolidada', value: 3 },
            { text: 'Vigente', value: 4 },
            { text: 'Finalizada', value: 5 }
        ];
        this.selectedTab = 1;
        this.loading = true;
        this.items = [];
        this.search = '';
        this.selectPeriodDate = [];
        this.tabelaRef = null;
        this.menu = false;
        this.selectedStatus = null;
        this.showFilterDialog = false;
        this.selectCheckbox = null;
        this.loadingSave = false;
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    clearFilters() {
        this.selectPeriodDate = [];
        this.search = '';
        this.selectedStatus = null;
        this.$forceUpdate();
    }
    get isClearFiltersDisabled() {
        return (this.selectPeriodDate.length === 0 &&
            this.search === '' &&
            this.selectedStatus === null);
    }
    applyFilters() {
        // Aplicar os filtros escolhidos aqui
        this.showFilterDialog = false;
    }
    get filteredParcerias() {
        return this.parcerias.filter(parceria => {
            // Filtragem por status
            const statusMatch = this.selectedStatus === null ||
                parceria.status_id === this.selectedStatus;
            // Filtragem por data de criação
            const dateMatch = this.selectPeriodDate.length === 0 ||
                (new Date(parceria.data_inicio) >= new Date(this.selectPeriodDate[0]) &&
                    new Date(parceria.data_inicio) <= new Date(this.selectPeriodDate[1]));
            return statusMatch && dateMatch;
        });
    }
    async onChangeSelectAllCheckbox(val) {
        if (this.loadingSave) {
            return;
        }
        this.loadingSave = true;
        await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao('list_parcerias');
        this.headers.forEach(header => {
            header.show = val.includes(header.value);
        });
        const items = this.headers.filter(item => item.show);
        await filtroPorTabelaStore.filtroPorTabelaCreate({
            items,
            table_name: 'list_parcerias'
        });
        this.loadingSave = false;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_view;
        }
    }
    get allowViewParcerias() {
        if (userToolStore.userToolsIndexed.Comercial) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_delete;
        }
    }
    get parcerias() {
        return parceriaStore.parcerias;
    }
    get headersFiltered() {
        return _.filter(this.headers, item => item.show);
    }
    showFiltroPorTabela() {
        const form = this.$refs.filtroPorTabela;
        form.show('parceria');
    }
    async onUpdateHeaders(headers) {
        this.headers = headers;
    }
    statusClass(value) {
        switch (value) {
            case 1:
                return 'status-prospeccao';
            case 2:
                return 'status-negociacao';
            case 3:
                return 'status-nao-consolidado';
            case 4:
                return 'status-vigente';
            case 5:
                return 'status-finalizada';
            default:
                return '';
        }
    }
    navigateToCreateParceria() {
        this.$router.push('/main/ComercialParcerias/create');
    }
    // public async deleteItem(id: number) {
    //   this.$swal({
    //     title: 'Confirma a exclusão da parceria?',
    //     text: 'Após exclusão a parceria não pode ser recuperada!',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     cancelButtonText: 'Cancelar',
    //     confirmButtonText: 'Sim, eu confirmo!',
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       this.loading = true;
    //       // @ts-ignore
    //       await parceriaStore.deleteParceria(id);
    //       // @ts-ignore
    //       await parceriaStore.getParcerias();
    //       this.loading = false;
    //     }
    //   });
    // }
    async created() {
        this.$nextTick(() => {
            this.tabelaRef = this.$refs.tabela;
        });
    }
    async mounted() {
        this.loading = true;
        await parceriaStore.getParcerias();
        await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao('list_parcerias');
        this.selectCheckbox = this.headers.map(item => item.value);
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-parcerias-edit', params: { id } });
    }
};
List = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            ImprimirTabelaComponent,
            TextExpandableComponent,
            PeriodSelectorComponent,
            CustomTabsComercialComponent,
            ButtonComponent
        }
    })
], List);
export default List;
